import React from 'react';
import styles from './section.module.less';

type SectionProps = {
  children?: React.ReactNode;
  className?: string;
};

const Section = (props: SectionProps) => {
  const { children, className = '' } = props;
  return <div className={`${styles.section} ${className}`}>{children}</div>;
};

export default Section;
