import { useIntl } from 'react-intl';

import { CDN_HOST } from 'common/utils/constant';
import Section from 'ui/Section';
import SectionContainer from 'ui/SectionContainer';
import GridFourToTwo from '../GridFourToTwo';
import Feature from './Feature';

import styles from './featureSection.module.less';

const FeatureSection = () => {
  const intl = useIntl();
  return (
    <Section className={styles.featureSection}>
      <SectionContainer>
        <GridFourToTwo>
          <Feature
            imgSrc={`${CDN_HOST}/pub/img/homeV2/icon-platform-simplicity.png`}
            imgWidth="50"
            imgHeight="18"
            title={intl.formatMessage({
              id: 'home.platform.benefit1',
              defaultMessage: 'Simplicity',
            })}
            text={intl.formatMessage({
              id: 'home.platform.benefit1.desc',
              defaultMessage:
                'We create one-stop remote worker management platform, it helps you multitask efficiently.',
            })}
          />
          <Feature
            imgSrc={`${CDN_HOST}/pub/img/homeV2/icon-platform-flexibility.png`}
            imgWidth="50"
            imgHeight="43"
            title={intl.formatMessage({
              id: 'home.platform.benefit2',
              defaultMessage: 'Flexibility',
            })}
            text={intl.formatMessage({
              id: 'home.platform.benefit2.desc',
              defaultMessage:
                "We handle all payroll tax headaches and local employees' compliance efficiently in 150+ countries.",
            })}
          />
          <Feature
            imgSrc={`${CDN_HOST}/pub/img/homeV2/icon-platformsecurity.png`}
            imgWidth="50"
            imgHeight="48"
            title={intl.formatMessage({
              id: 'home.platform.benefit3',
              defaultMessage: 'Compliant',
            })}
            text={intl.formatMessage({
              id: 'home.platform.benefit3.desc',
              defaultMessage:
                'We take on all responsibility and risk, it will be a hassle-free process by our Global EOR and Payroll team.',
            })}
          />
          <Feature
            imgSrc={`${CDN_HOST}/pub/img/homeV2/icon-platform-support.png`}
            imgWidth="50"
            imgHeight="32"
            title={intl.formatMessage({
              id: 'home.platform.benefit4',
              defaultMessage: 'Expert Support',
            })}
            text={intl.formatMessage({
              id: 'home.platform.benefit4.desc',
              defaultMessage:
                'We offer regional HR expert support, always on call for you 24/7/365. Any question? Ask Slasify!',
            })}
          />
        </GridFourToTwo>
      </SectionContainer>
    </Section>
  );
};

export default FeatureSection;
