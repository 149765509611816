import WebpImage from 'ui/WebpImage';
import styles from './feature.module.less';

type FeatureProps = {
  imgSrc: string;
  imgWidth: string;
  imgHeight: string;
  title: string;
  text: string;
};

const Feature = ({ imgSrc, imgWidth, imgHeight, title, text }: FeatureProps) => {
  return (
    <div className={styles.feature}>
      <WebpImage
        className={styles.imgContainer}
        src={imgSrc}
        width={imgWidth}
        height={imgHeight}
        alt="Feature Images"
      />

      <div className={styles.title}>{title}</div>
      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default Feature;
