import styles from './dataItem.module.less';

type DataItemProps = {
  icon: React.ReactNode;
  number: string;
  unit: string;
};

const DataItem = ({ icon, number, unit }: DataItemProps) => {
  return (
    <div className={styles.dataItem}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.right}>
        <div className={styles.number}>{number}</div>
        <div className={styles.unit}>{unit}</div>
      </div>
    </div>
  );
};

export default DataItem;
