import type { ButtonProps } from 'antd';
import { Button } from 'antd';

import styles from './requestBtn.module.less';

type RequestBtnProps = Omit<ButtonProps, 'type' | 'className'>;

const RequestBtn = ({ ...props }: RequestBtnProps) => {
  return (
    <div className={styles.linkBtn}>
      <Button data-testid={'linkBtn'} {...props} className={styles.primaryBtn} type="primary" />
    </div>
  );
};

export default RequestBtn;
