import { useIntl } from 'react-intl';

import DataSection from '@/features/home/components/DataSection';
import FeatureSection from '@/features/home/components/FeatureSection';
import PartnerLogoSection from '@/features/home/components/PartnerLogoSection';
import PayrollSection from '@/features/home/components/PayrollSection';
import PlatformSection from '@/features/home/components/PlatformSection';
import ReviewSection from '@/features/home/components/ReviewSection';
import SloganSection from '@/features/home/components/SloganSection';
import WorldSection from '@/features/home/components/WorldSection';
import { HomepageData } from '@/type/data';
import HCFLayout from 'ui/HCFLayout';
import HeadTitle from 'ui/HeadTitle';

type HomeProps = {
  data: HomepageData;
};

const Home = ({ data }: HomeProps) => {
  const intl = useIntl();
  const { payPrice, hours } = data;

  return (
    <HCFLayout>
      <HeadTitle
        title={intl.formatMessage({
          id: 'home.title',
          defaultMessage: 'Remote Work: Assemble a Remotely Global Team',
        })}
      />
      <SloganSection />
      <DataSection payPrice={payPrice} hours={hours} />
      <PlatformSection />
      <FeatureSection />
      <WorldSection />
      <PayrollSection />
      <ReviewSection />
      <PartnerLogoSection />
    </HCFLayout>
  );
};

export default Home;
