import styles from './subjectText.module.less';

type SubjectTextProps = {
  children: string;
  className?: string;
};

const SubjectText = ({ children, className = '' }: SubjectTextProps) => {
  return (
    <div className={styles.subjectContainer}>
      <div className={`${styles.subjectText} ${className}`}>{children}</div>
    </div>
  );
};

export default SubjectText;
