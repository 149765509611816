import styles from './carouselCard.module.less';

type CarouselCardProps = {
  companyName: string;
  jobTitle: string;
  contentText: string;
};

const CarouselCard = ({ companyName, jobTitle, contentText }: CarouselCardProps) => {
  return (
    <div className={styles.carouselCard}>
      <div className={styles.header}>
        <div className={styles.companyName}>{companyName}</div>
        <div className={styles.jobTitle}>{jobTitle}</div>
      </div>
      <div className={styles.textContainer}>
        <i className={styles.contentText}>{contentText}</i>
      </div>
    </div>
  );
};

export default CarouselCard;
