import * as math from 'mathjs';

import { constant } from 'common/utils/constant';

const { en_us, zh_tw, zh_cn } = constant;

export const getPriceNumberAndUnit = (intlLocale: string, price: number) => {
  switch (intlLocale) {
    case zh_tw:
      return [math.round(price / 10000, 1), '萬'];

    case zh_cn:
      return [math.round(price / 10000, 1), '万'];

    case en_us:
    default:
      return [math.round(price / 1000000, 1), 'Million'];
  }
};

export const getHourNumnerAndUnit = (intlLocale: string, hour: number) => {
  switch (intlLocale) {
    case zh_tw:
      return [math.round(hour / 10000, 1), '萬'];

    case zh_cn:
      return [math.round(hour / 10000, 1), '万'];

    case en_us:
    default:
      return [math.round(hour / 1000, 1), 'Thousand'];
  }
};
