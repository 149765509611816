import { Carousel } from 'antd';
import { useIntl } from 'react-intl';

import Section from 'ui/Section';
import SectionTitle from '../SectionTitle';
import SubjectText from '../SubjectText';
import CarouselCard from './CarouselCard';

import styles from './reviewSection.module.less';

const ReviewSection = () => {
  const intl = useIntl();
  const reviews = [
    {
      companyName: 'Kdan Mobile Software Co., Ltd.',
      jobTitle: 'HR Manager',
      contentText:
        'Since remote working is a global trend, Slasify’s solution offers a great solution to help us recruit the best-fit talents internationally.',
    },
    {
      companyName: intl.formatMessage({
        id: 'home.story2.company',
        defaultMessage: 'Fortune 500 Company in Food&Beverage',
      }),
      jobTitle: intl.formatMessage({
        id: 'home.story2.title',
        defaultMessage: 'Regional Manager',
      }),
      contentText:
        "While seeking resources to launch our regional event, we came across Slasify with recommendations from our regional partner. Slasify's capacity to efficiently assembled a team of talents to complete the project with single point-of-contact project manager saved us a lot of foreseeable trouble of communication. Remarkable service.",
    },
    {
      companyName: intl.formatMessage({
        id: 'home.story3.company',
        defaultMessage: 'Leading Broadcasting Television Network in Malaysia',
      }),
      jobTitle: intl.formatMessage({
        id: 'home.story3.title',
        defaultMessage: 'Head of Operation',
      }),
      contentText:
        "Slasify has helped us with our business development in Vietnam, Philippines, Indonesia, and Malaysia. In the area that we would have spent too much resources to explore, Slasify handled the tasks professionally and efficiently. Truly a trustable partner we can't live without.",
    },
    {
      companyName: intl.formatMessage({
        id: 'home.story4.company',
        defaultMessage: 'Fortune 100 Tech Company.',
      }),
      jobTitle: intl.formatMessage({
        id: 'home.story4.title',
        defaultMessage: 'Senior Consultant',
      }),
      contentText:
        'In our industry, we are very familiar with the term "outsourcing", Slasify\'s solution indeed gives us an new option for our sprint project. I believe they could solve big problem.',
    },
  ];

  return (
    <Section className={styles.reviewSection}>
      <div className={styles.container}>
        <SubjectText className={styles.subjectText}>TRUSTED BY CUSTOMERS</SubjectText>
        <SectionTitle className={styles.sectionTitle}>
          {intl.formatMessage({
            id: 'home.review.title',
            defaultMessage: 'What Our Customers Are Saying',
          })}
        </SectionTitle>
        <Carousel autoplay autoplaySpeed={5000}>
          {reviews.map((review) => (
            <CarouselCard
              key={review.companyName}
              companyName={review.companyName}
              jobTitle={review.jobTitle}
              contentText={review.contentText}
            />
          ))}
        </Carousel>
      </div>
    </Section>
  );
};

export default ReviewSection;
