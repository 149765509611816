import React from 'react';
import styles from './sectionContainer.module.less';

type SectionContainerProps = {
  children: React.ReactNode;
  className?: string;
};

const SectionContainer = (props: SectionContainerProps) => {
  const { children, className = '' } = props;
  return <div className={`${styles.container} ${className}`}>{children}</div>;
};

export default SectionContainer;
