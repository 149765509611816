import { useIntl } from 'react-intl';

import config from 'common/config';
import { CDN_HOST } from 'common/utils/constant';
import ContentCard from 'ui/ContentCard';
import RequestBtn from 'ui/RequestBtn';
import Section from 'ui/Section';
import SectionContainer from 'ui/SectionContainer';
import CardImage from '../CardImage';
import SectionTitle from '../SectionTitle';
import SubjectText from '../SubjectText';
import PlatformFunction from './PlatformFunction';

import styles from './platformSection.module.less';

const PlatformSection = () => {
  const intl = useIntl();

  const platformFunctions = [
    {
      title: intl.formatMessage({
        id: 'home.platform.service1',
        defaultMessage: 'Consultation',
      }),
      text: intl.formatMessage({
        id: 'home.platform.service.desc1',
        defaultMessage: 'Dedicated Experts for International Employment',
      }),
    },
    {
      title: intl.formatMessage({
        id: 'home.platform.service2',
        defaultMessage: 'Hire',
      }),
      text: intl.formatMessage({
        id: 'home.platform.service.desc2',
        defaultMessage: 'Post Unlimited Remote Job Vacancies for Free',
      }),
    },
    {
      title: intl.formatMessage({
        id: 'home.platform.service3',
        defaultMessage: 'Onboard',
      }),
      text: intl.formatMessage({
        id: 'home.platform.service.desc3',
        defaultMessage: 'Onboard employees easily and multitask efficiently',
      }),
    },
    {
      title: intl.formatMessage({
        id: 'home.platform.service4',
        defaultMessage: 'Payroll',
      }),
      text: intl.formatMessage({
        id: 'home.platform.service.desc4',
        defaultMessage: 'One-click to Pay for your worldwide Remote Team',
      }),
    },
  ];

  return (
    <Section className={styles.platformSection}>
      <SectionContainer>
        <ContentCard>
          <SubjectText className={styles.subjectText}>WHY SLASIFY</SubjectText>
          <SectionTitle className={styles.sectionTitle}>
            {intl.formatMessage({
              id: 'home.platform.title',
              defaultMessage: 'One-step Remote Worker Management Platform',
            })}
          </SectionTitle>
          <div className={styles.functionItems}>
            {platformFunctions.map((platformFunction) => (
              <PlatformFunction
                key={platformFunction.title}
                title={platformFunction.title}
                text={platformFunction.text}
              />
            ))}
          </div>
          <div className={styles.requestBtn}>
            <RequestBtn href={`${config.webUrl}/${intl.locale}/request-form`}>
              {intl.formatMessage({
                id: 'btn.request.demo',
                defaultMessage: 'Request a Demo',
              })}
            </RequestBtn>
          </div>
        </ContentCard>
        <ContentCard>
          <CardImage
            className={styles.platformImg}
            imgSrc={`${CDN_HOST}/pub/img/homeV2/img-platform.png`}
            width="420"
            height="426"
            alt="Platform Image"
          />
        </ContentCard>
      </SectionContainer>
    </Section>
  );
};

export default PlatformSection;
