import { useIntl } from 'react-intl';

import config from 'common/config';
import RequestBtn from 'ui/RequestBtn';
import SectionTitle from '../SectionTitle';
import SubjectText from '../SubjectText';
import styles from './subjectCard.module.less';

type SubjectCardProps = {
  subjectText: string;
  subjectTextCss: string;
  sectionTitle: string;
  contentText: string;
};

const SubjectCard = ({
  subjectText,
  subjectTextCss,
  sectionTitle,
  contentText,
}: SubjectCardProps) => {
  const intl = useIntl();

  return (
    <>
      <SubjectText className={subjectTextCss}>{subjectText}</SubjectText>
      <SectionTitle>{sectionTitle}</SectionTitle>
      <div className={styles.contentText}>{contentText}</div>
      <div className={styles.requestBtn}>
        <RequestBtn href={`${config.webUrl}/${intl.locale}/request-form`}>
          {intl.formatMessage({
            id: 'btn.request.demo',
            defaultMessage: 'Request a Demo',
          })}
        </RequestBtn>
      </div>
    </>
  );
};

export default SubjectCard;
