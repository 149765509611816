import { useIntl } from 'react-intl';

import { CDN_HOST } from 'common/utils/constant';
import ContentCard from 'ui/ContentCard';
import Section from 'ui/Section';
import SectionContainer from 'ui/SectionContainer';
import CardImage from '../CardImage';
import SubjectCard from '../SubjectCard';

import styles from './worldSection.module.less';

const WorldSection = () => {
  const intl = useIntl();

  return (
    <Section className={styles.worldSection}>
      <SectionContainer>
        <ContentCard>
          <SubjectCard
            subjectText={intl.formatMessage({
              id: 'home.world.subject',
              defaultMessage: 'Featured Services',
            })}
            subjectTextCss={styles.subjectText}
            sectionTitle={intl.formatMessage({
              id: 'home.world.title',
              defaultMessage: 'International Compliant Employment',
            })}
            contentText={intl.formatMessage({
              id: 'home.world.desc',
              defaultMessage:
                'We aim to support employers focus on global expansion by hiring remote talents, without setting up their own entities in different countries. We have over 10,000 remote talents in our network with over 80% of which are in the IT sector and around 1,000 talents are currently deployed.',
            })}
          />
        </ContentCard>
        <ContentCard>
          <CardImage
            imgSrc={`${CDN_HOST}/pub/img/homeV2/img-world2.png`}
            width="420"
            height="149"
            alt="World Image"
          />
        </ContentCard>
      </SectionContainer>
    </Section>
  );
};

export default WorldSection;
