import { useIntl } from 'react-intl';
import CalendarCheckLineIcon from 'remixicon-react/CalendarCheckLineIcon';
import CurrencyLineIcon from 'remixicon-react/CurrencyLineIcon';
import GlobalLineIcon from 'remixicon-react/GlobalLineIcon';
import TimeLineIcon from 'remixicon-react/TimeLineIcon';

import { HomepageData } from '@/type/data';
import config from 'common/config';
import { homepageData } from 'common/utils/constant';
import { getHourNumnerAndUnit, getPriceNumberAndUnit } from 'common/utils/unitTransform';
import RequestBtn from 'ui/RequestBtn';
import Section from 'ui/Section';
import SectionContainer from 'ui/SectionContainer';
import DataItem from './DataItem';

import styles from './dataSection.module.less';

type DataSectionProps = {
  payPrice: HomepageData['payPrice'];
  hours: HomepageData['hours'];
};

const { COUNTRIES, CURRENCIES } = homepageData;

const DataSection = ({ payPrice, hours }: DataSectionProps) => {
  const intl = useIntl();

  const [priceNumber, priceUnit] = getPriceNumberAndUnit(intl.locale, payPrice);
  const [hourNumber, hourUnit] = getHourNumnerAndUnit(intl.locale, hours);

  const hourI18n = intl.formatMessage({
    id: 'home.data.hour.unit',
    defaultMessage: 'Hours',
  });

  const dataItems = [
    {
      icon: <GlobalLineIcon />,
      number: COUNTRIES,
      unit: intl.formatMessage({
        id: 'home.data.country.unit',
        defaultMessage: 'Countries',
      }),
    },
    {
      icon: <CalendarCheckLineIcon />,
      number: String(priceNumber),
      unit: `${priceUnit} $USD`,
    },
    {
      icon: <TimeLineIcon />,
      number: String(hourNumber),
      unit: `${hourUnit} ${hourI18n}`,
    },
    {
      icon: <CurrencyLineIcon />,
      number: CURRENCIES,
      unit: intl.formatMessage({
        id: 'home.data.currency.unit',
        defaultMessage: 'Currencies',
      }),
    },
  ];

  return (
    <Section className={styles.dataSection}>
      <SectionContainer className={styles.sectionContainer}>
        <div className={styles.upper}>
          {dataItems.map((dataItem, index) => (
            <DataItem
              key={index}
              icon={dataItem.icon}
              number={dataItem.number}
              unit={dataItem.unit}
            />
          ))}
        </div>
        <div className={styles.middle}>
          {intl.formatMessage({
            id: 'home.data.desc',
            defaultMessage:
              'We help you to improve your remote and international onboarding process. Going remote? Ask Slasify!',
          })}
        </div>
        <RequestBtn href={`${config.webUrl}/${intl.locale}/request-form`}>
          {intl.formatMessage({
            id: 'btn.request.demo',
            defaultMessage: 'Request a Demo',
          })}
        </RequestBtn>
      </SectionContainer>
    </Section>
  );
};

export default DataSection;
