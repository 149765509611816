import { CDN_HOST } from 'common/utils/constant';
import Section from 'ui/Section';
import SectionContainer from 'ui/SectionContainer';
import GridFourToTwo from '../GridFourToTwo';
import LinkImage from './LinkImage';

import styles from './partnerLogoSection.module.less';

const PartnerLogoSection = () => {
  return (
    <Section>
      <SectionContainer>
        <GridFourToTwo className={styles.grid}>
          <LinkImage
            href="https://blog.slasify.com/"
            imgSrc={`${CDN_HOST}/pub/img/logo/media/TAE35.png`}
            width="149"
            height="74"
            alt="Louis"
          />
          <LinkImage
            href="https://blog.slasify.com/"
            imgSrc={`${CDN_HOST}/pub/img/logo/media/TAE38.png`}
            width="149"
            height="74"
            alt="36Kr"
          />
          <LinkImage
            href="https://blog.slasify.com/"
            imgSrc={`${CDN_HOST}/pub/img/logo/media/TAE37.png`}
            width="149"
            height="74"
            alt="Korea"
          />
          <LinkImage
            href="https://blog.slasify.com/"
            imgSrc={`${CDN_HOST}/pub/img/logo/media/TAE33.png`}
            width="149"
            height="64"
            alt="TAE33"
          />
        </GridFourToTwo>
      </SectionContainer>
    </Section>
  );
};

export default PartnerLogoSection;
