import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import styles from './platformFunction.module.less';

type PlatformFunctionProps = {
  title: string;
  text: string;
};

const PlatformFunction: React.FC<PlatformFunctionProps> = (props) => {
  const { title, text } = props;

  return (
    <div className={styles.platformFunction}>
      <div className={styles.left}>
        <FontAwesomeIcon icon={faCheckCircle} className={styles.icon} />
      </div>
      <div className={styles.right}>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  );
};

export default PlatformFunction;
