import styles from './cardImage.module.less';

import WebImage from 'ui/WebpImage';

type CardImageProps = {
  className?: string;
  imgSrc: string;
  width: string;
  height: string;
  alt?: string;
};

const CardImage = ({ className = '', imgSrc, width, height, alt }: CardImageProps) => {
  return (
    <WebImage
      className={`${styles.cardImage} ${className}`}
      src={imgSrc}
      width={width}
      height={height}
      alt={alt}
    />
  );
};

export default CardImage;
