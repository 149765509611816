import WebpImage from 'ui/WebpImage';

import styles from './linkImage.module.less';

type LinkImageProps = {
  href: string;
  imgSrc: string;
  width: string;
  height: string;
  alt?: string;
};

const LinkImage = ({ href, imgSrc, width, height, alt }: LinkImageProps) => {
  return (
    <div className={styles.linkImg}>
      <a href={href}>
        <WebpImage src={imgSrc} width={width} height={height} alt={alt} />
      </a>
    </div>
  );
};

export default LinkImage;
