import styles from './gridFourToTwo.module.less';

type GridFourToTwoProps = {
  className?: string;
  children: React.ReactNode;
};
const GridFourToTwo = ({ className = '', children }: GridFourToTwoProps) => {
  return <div className={`${styles.gridFourToTwo} ${className}`}>{children}</div>;
};

export default GridFourToTwo;
