import { useIntl } from 'react-intl';

import config from 'common/config';
import { CDN_HOST } from 'common/utils/constant';
import BlankLink from 'ui/BlankLink';
import ContentCard from 'ui/ContentCard';
import RequestBtn from 'ui/RequestBtn';
import Section from 'ui/Section';
import SectionContainer from 'ui/SectionContainer';
import WebpImage from 'ui/WebpImage';

import styles from './sloganSection.module.less';

const SloganSection = () => {
  const intl = useIntl();

  return (
    <Section className={styles.sloganSection}>
      <SectionContainer>
        <ContentCard className={styles.leftCard}>
          <BlankLink
            href="https://blog.slasify.com/"
            content={intl.formatMessage({
              id: 'home.blog.text',
              defaultMessage: 'Know more about remote work >',
            })}
            isTrusted={true}
          />
          <div className={styles.gradientSlogan}>
            {intl.formatMessage(
              {
                id: 'home.main.slogan',
                defaultMessage: 'Slasify, We Satisfy Your Global Payroll and EOR Needs',
              },
              { br: <br /> },
            )}
          </div>
          <div className={styles.sloganTextBold}>
            {intl.formatMessage({
              id: 'home.main.sub.slogan',
              defaultMessage: 'Change How You Work and Pay Remotely.',
            })}
          </div>
          <div className={styles.sloganText}>
            {intl.formatMessage({
              id: 'home.main.desc',
              defaultMessage:
                'Slasify is a global payroll and Employer of Record service, we help businesses to run their operations efficiently and legally around the world through our workforce management system in conjunction with our trusted network of partners.',
            })}
          </div>
          <div>
            <div className={styles.btnContainer}>
              <RequestBtn href={`${config.webUrl}/${intl.locale}/request-form`}>
                {intl.formatMessage({
                  id: 'btn.request.demo',
                  defaultMessage: 'Request a Demo',
                })}
              </RequestBtn>
            </div>
          </div>
        </ContentCard>
        <ContentCard className={styles.rightCard}>
          <WebpImage
            width="508"
            height="588"
            src={`${CDN_HOST}/pub/img/homeV2/img-slogan.png`}
            alt="Slogan Image"
            loading="eager"
          />
        </ContentCard>
      </SectionContainer>
    </Section>
  );
};

export default SloganSection;
