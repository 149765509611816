import styles from './sectionTitle.module.less';

type SectionTitleProps = {
  children: string;
  className?: string;
};

const SectionTitle = ({ children, className = '' }: SectionTitleProps) => {
  return <div className={`${styles.sectionTitle} ${className}`}>{children}</div>;
};

export default SectionTitle;
