import { getHomepageData } from '@/api/homeAPI';
import Home from '@/features/home/pages/';
import { GetStaticProps } from 'next';

export default Home;

export const getStaticProps: GetStaticProps = async () => {
  const data = await getHomepageData();

  return {
    props: {
      data,
    },
    revalidate: 24 * 60 * 60, // In seconds => one day
  };
};
