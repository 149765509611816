import { useIntl } from 'react-intl';

import { CDN_HOST } from 'common/utils/constant';
import ContentCard from 'ui/ContentCard';
import Section from 'ui/Section';
import SectionContainer from 'ui/SectionContainer';
import WebpImage from 'ui/WebpImage';
import CardImage from '../CardImage';
import GridFourToTwo from '../GridFourToTwo';
import SubjectCard from '../SubjectCard';

import styles from './payrollSection.module.less';

const PayrollSection = () => {
  const intl = useIntl();

  return (
    <Section className={styles.payrollSection}>
      <SectionContainer>
        <ContentCard>
          <SubjectCard
            subjectText={intl.formatMessage({
              id: 'home.payroll.subject',
              defaultMessage: 'Feature Services',
            })}
            subjectTextCss={styles.subjectText}
            sectionTitle={intl.formatMessage({
              id: 'home.payroll.title',
              defaultMessage: 'Global Multi-Currencies Payroll Service',
            })}
            contentText={intl.formatMessage({
              id: 'home.payroll.desc',
              defaultMessage:
                'Slasify has strong experiences in providing cross-border payments to over 150+ countries. We pride ourselves on the speed that we can execute projects through our platform (usually within 24-48 hours depending on which region of the world the clients are in).',
            })}
          />
          <GridFourToTwo className={styles.payrollIcons}>
            <WebpImage
              src={`${CDN_HOST}/pub/img/homeV2/stripe.png`}
              width="73"
              height="30"
              alt="stripeImg"
            />
            <WebpImage
              src={`${CDN_HOST}/pub/img/homeV2/wise.png`}
              width="88"
              height="20"
              alt="wiseImg"
            />
            <WebpImage
              src={`${CDN_HOST}/pub/img/homeV2/payoneer.png`}
              width="88"
              height="35"
              alt="payoneerImg"
            />
            <WebpImage
              src={`${CDN_HOST}/pub/img/homeV2/banktransfer.png`}
              width="78"
              height="36"
              alt="banktransferImg"
            />
          </GridFourToTwo>
        </ContentCard>
        <ContentCard>
          <CardImage
            imgSrc={`${CDN_HOST}/pub/img/homeV2/img-payroll.png`}
            width="420"
            height="466"
            alt="Payroll Image"
          />
        </ContentCard>
      </SectionContainer>
    </Section>
  );
};

export default PayrollSection;
