import styles from './contentCard.module.less';

type ContentCardProps = {
  children: React.ReactNode;
  className?: string;
};

const ContentCard = ({ children, className = '' }: ContentCardProps) => {
  return <div className={`${styles.contentCard} ${className}`}>{children}</div>;
};

export default ContentCard;
